import * as React from 'react'

import { StoryStreamWidget } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  StoryStream,
  StoryStreamProps,
} from '@thg-commerce/enterprise-widget-story-stream'
import { useSiteConfig } from '@thg-commerce/enterprise-core'

export type StoryStreamWidgetRendererProps = Omit<
  StoryStreamWidget,
  'query' | 'id'
>

export const StoryStreamWidgetRenderer = (
  props: StoryStreamWidgetRendererProps,
) => {
  const { storyStreamJavaScriptBaseUrl } = useSiteConfig()

  if (
    !props.storyStreamId ||
    !props.integrityHash ||
    !props.layoutClass ||
    !storyStreamJavaScriptBaseUrl
  ) {
    return null
  }

  const storyStreamProps: StoryStreamProps = {
    storyStreamJavaScriptBaseUrl,
    layoutClass: props.layoutClass,
    storyStreamId: props.storyStreamId,
    integrityHash: props.integrityHash,
    addStoryStreamTitle: false,
  }

  return <StoryStream {...storyStreamProps} />
}
